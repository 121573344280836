<template>
    <div class="w-100 mb-0" @click="toggleSection">
        <header-tag
            heading="" :color="color" class="text-primary font-poppins-medium" :border-bottom="borderBottom"
            :border-top="borderTop">
            <template #header>
                <div class="fl-y text-white">
                    <div class="d-block text-danger" style="display: block;">{{ heading }}</div>
                    <div style="display: block;">{{ discription }}</div>
                </div>

            </template>
<!--            <template #buttons>-->
<!--                <transition name="fade-accordion" mode="out-in" v-if="showButtons">-->
<!--                    <btn size="sm" icon="fa fa-chevron-down  cursor-ponter"-->
<!--                         class="border-rounded lego-btn-transparent " color=""-->
<!--                         key="open-btn" @click="toggleSection" v-if="!sectionVisibility"-->
<!--                         :class="{'text-white btn-text-white lego-btn-chevron-open':color==='primary','text-1':accordionWhite}"-->
<!--                    ></btn>-->

<!--                    <btn size="sm" icon="fa fa-chevron-up cursor-ponter "-->
<!--                         class="border-rounded lego-btn-transparent" color=""-->
<!--                         key="close-btn" @click="toggleSection" v-else-->
<!--                         :class="{'text-white btn-text-white lego-btn-chevron-close':color==='primary','text-1': accordionWhite}"-->
<!--                    ></btn>-->
<!--                </transition>-->
<!--            </template>-->
        </header-tag>
        <transition name="fade">
            <section :class="{'pt-3':sectionVisibility, 'bg-primary': color === 'primary'}" v-if="sectionVisibility">
                <slot></slot>
            </section>
        </transition>
    </div>
</template>
<script>
import HeaderTag from '@components/ui/HeaderTag';

export default {
    name: 'BaseAccordion',
    components: { HeaderTag },
    data () {
        return { sectionVisibility: false };
    },
    props: {
        heading: {
            type: String
        },
        discription: {
            type: String
        },
        color: {
            type: String,
            default: 'white'
        },
        borderTop: {
            type: Boolean,
            default: true
        },
        borderBottom: {
            type: Boolean,
            default: false
        },
        showButtons: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        accordionWhite () {
            console.log('accordion', this.color === 'basic' || this.color === 'white');
            return this.color === 'basic' || this.color === 'white';
        }
    },
    methods: {
        toggleSection () {
            this.sectionVisibility = !this.sectionVisibility;
            if (this.sectionVisibility && this.color === 'white') {
                // if (this.sectionVisibility) {
                this.color = 'basic';
            } else if (!this.sectionVisibility && this.color === 'basic') {
                // } else if (!this.sectionVisibility) {
                this.color = 'white';
            }
        }
    }
};
</script>
<style scoped> .fade-accordion-enter-active, .fade-leave-active {
    transition: opacity .3s;
}
.gray{
    background-color: #F8F8F8;
}

.btn-block .lego-btn {
    color: var(--color-primary) !important;
    background-color: #F4F4F4;
}

.fade-accordion-enter, .fade-accordion-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
} </style>
