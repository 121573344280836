import store from '../store';

const getMenu = function () {
    const trainingMenu = store.getters.currentUser.training_menu;
    const servicesMenu = store.getters.currentUser.services_menu;

    const menu = [
        {
            icon: '',
            text: 'About',
            link: '/about/'
            // link: '/'
        },
        {
            text: 'Services',
            link: '#',
            class: 'c-submenu-indicator',
            items: servicesMenu
        },
        {
            text: 'Edu Tech Services',
            link: '#',
            class: 'c-submenu-indicator',
            items: trainingMenu
        },
        {
            icon: '',
            text: 'Gallery',
            class: 'c-submenu-indicator',
            link: '#',
            items: [
                {
                    text: 'Photo Gallery',
                    link: '/photo-gallery/'
                },
                {
                    text: 'Video Gallery',
                    link: '/video-gallery/'
                }
            ]
        },
        {
            icon: '',
            text: 'Contact Us',
            link: '/contact/'
            // link: '/'
        },
        {
            icon: '',
            text: 'Career',
            link: '/career/'
        },
        {
            icon: '',
            text: 'Partners',
            link: '/partners/'
            // link: '/'
        }
        // {
        //     icon: '',
        //     text: 'Calendar',
        //     event: '#',
        //     type: 'button',
        //     class: '--d-unset ml-3 text-center',
        //     // link: '/career/',
        //     link: '/',
        //     btnAttrs: {
        //         design: 'basic-a',
        //         color: 'danger',
        //         class: 'register-btn font-poppins-regular font-weight-light fs-1'
        //     }
        // }

    ];
    return [
        ...menu
    ];
};
export default getMenu;
