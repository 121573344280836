<template>
    <div>
        <div class="form">
            <s-form class="mt-4 col-lg-12 pb-4">
                <div class="row">

                    <div class="col-lg-12 col-12">
                        <validated-select size="m" border-radius="1" placeholder=" Select Course"
                                          class="py-1 text-primary font-manrope-medium mb-0 d-input-career-c c-input-select-blue"></validated-select>
                    </div>

                    <div class="col-lg-12 col-12">
                        <validated-select size="m" border-radius="1" placeholder="Select date"
                                          class="py-1 text-primary font-manrope-medium mb-0 d-input-career-c c-input-select-blue "></validated-select>
                    </div>

                    <div class="col-lg-12 col-12">
                        <validated-select size="m" border-radius="1" placeholder="Select Country"
                                          class="py-1 mb-0 font-manrope-medium text-primary d-input-career-c c-input-select-blue"></validated-select>
                    </div>
                    <div class="col-lg-12 col-12">
                        <validated-input size="m" border-radius="1" placeholder="Select City"
                                         class="py-1 mb-0 font-manrope-medium text-primary d-input-career-c c-input-select-blue"></validated-input>
                    </div>
                    <div class="col-lg-12 col-12">
                        <validated-input size="m" border-radius="1" placeholder="Name"
                                         class="py-1 mb-0 font-manrope-medium text-primary d-input-career-c "></validated-input>
                    </div>
                    <div class="col-lg-12 col-12">
                        <validated-input size="m" border-radius="1" placeholder="Email"
                                         class="py-1  mb-0 font-manrope-medium text-primary d-input-career-c "></validated-input>
                    </div>
                    <div class="col-lg-12 col-12">
                        <validated-input size="m" border-radius="1" placeholder="Contact Number"
                                         class="py-1  mb-0 font-manrope-medium text-primary d-input-career-c "></validated-input>
                    </div>
                </div>
                <div class="mt-5 fl-x-cc">
                    <mark class="grey text-center p-1  mb-0 ash"></mark>
                </div>
                <div class="fl-x-cc mt-0">
                    <validated-input placeholder="captcha"
                                     class="font-manrope-medium text-primary d-input-career-c"></validated-input>
                </div>
                <div class=" mt-4 mb-4 ">

                    <div class="fl-x-cc">
                        <btn text="Register" border-radius="0" color="danger"
                             class="px-4 font-manrope-regular"></btn>
                    </div>
                </div>
            </s-form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TrainingCertificationForm'
};
</script>

<style scoped>
.bg-blue {
    background-color: #0a0a58;
}

.text-white {
    color: white
}

.grey {
    background-color: #d9d9d9 !important;
}

.ash {
    height: 42px;
    width: 182px;
}

.blue {
    border-radius: 10px 10px 0px 0px;
    justify-content: center;
}

.form {
    border: 1px solid #fff6f6;
    width: 600px;
    border-radius: 14px
}

@media (max-width: 765px) {
    .form {
        width: 361px;
    }
}
</style>
