<template>
    <div class="bg-layer">
    </div>
</template>

<script>
export default {
    name: 'FooterResponsiveTopLayer'
};
</script>

<style scoped lang="scss">
.bg-layer {
    background-image: url("../../assets/web/bg/footer/footer-bg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100vw;
    height: 37vh;
    margin-bottom: -1rem !important;

    @media (min-width: 768px) and (max-width: 1024px) {
        height: 35vh;
    }
    @media (min-width: 992px) {
            height: 28vh;
    }

}
</style>
