<template>
    <div class="pt-lg-3 pb-2 pr-lg-5 pr-0 fl-x fl-te-t"
         :class="{'bg-primary text-white':color === 'primary','text-1 bg-4': color === 'basic','text-1 bg-2': color === 'white','text-white':color==='',
        'c-bb-1':borderBottom}">
        <div>
            <h5 v-if="heading !==''" class="">{{ heading }}</h5>
            <div class="fl-x" v-else>
                <slot name="header"></slot>
            </div>
        </div>
        <div class="btn-group btn-block">
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderTag',
    props: {
        heading: {
            type: String,
            default: ''
        },
        headingBold: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: ''
        },
        accordionHeader: {
            type: Boolean,
            default: false
        },
        borderTop: {
            type: Boolean,
            default: true
        },
        borderBottom: {
            type: Boolean,
            default: false
        }

    }
};
</script>

<style lang="scss" scoped>
h5 {
    margin: 0;
}

.fa {
    color: black;
}

.btn-block {
    .lego-btn {
        color: black !important;

        span {
            color: black !important;
        }
    }
}

.border-r-1 {
    border-radius: 0 0 0 0;
}

.border-r-2 {
    border-radius: 9px 9px 0 0;
}

.border-r-0 {
    border-radius: 0;
}

.c-bb-1 {
    //border-bottom: 0.4px solid rgba(228, 233, 242, 0.2);
    border-bottom: 0.4px solid rgba(228, 233, 242, 0.8);
}

.c-bt-1 {
    //border-top: 0.4px solid rgba(228, 233, 242, 0.2);
    //background-color: #F8F8F8;
    //border-top: 0.4px solid rgba(228, 233, 242, 0.8);
    border-top: 0.4px solid #858585;
}

.d-blue {
    color: darkblue;
}
</style>
