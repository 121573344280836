<template>
    <div class="footer-contain pt-3 mt-lg-6">
        <footer-responsive-top-layer class="d-lg-none"></footer-responsive-top-layer>
        <footer class="footer-bg bg-2 text-3 footer-fonts pt-lg-9 pb-4">
            <div class="pt-6 top-footer-cont">
                <div class="container">
                    <div class="px-lg-3">
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                                <div class="fl-y-sm-c px-4 px-lg-0 mt-n6 mt-md-n8 mt-lg-0">
                                    <a href="" class="">
                                        <img src="../../assets/web/Logo/cyberfort-white.png"
                                             class="img-fluid footer-logo"
                                             alt="">
                                    </a>
                                    <address class="c-address mt-3 m-0 fs-lg--1 text-3">
                                        <p class="mb-2 text-justify">
                                            Cyberfort is focused to provide professional services to organization and
                                            helps
                                            them to build control against cybercrime, protect business critical data and
                                            reduce cyber risk.
                                        </p>
                                    </address>
                                </div>
                            </div>

                            <!--                    <div class="col-12 col-md-6 col-lg-2 d-sm-none mb-4"/>-->

                            <div class="col-12 col-md-6 col-lg d-sm-none d-md-none mb-3 mb-lg-0">
                                <div class="fl-y fl-a-s ml-lg-5 d-md-none">
                                    <h2 class="mb-4 font-lato-bold fs-lg-1 text-danger">COMPANY</h2>
                                    <ul class="list-unstyled mb-3 ul-footer fs-lg--1">
                                        <li class="mb-1">
                                            <router-link to="/about/" class="d-block">About</router-link>
                                        </li>
                                        <!--                                        <li class="c-mb-11">-->
                                        <!--                                            <a href="#services" class="d-block ">Services</a>-->
                                        <!--                                        </li>-->
                                        <li class="c-mb-11">
                                            <router-link to="/blog-article/" class="d-block ">Blog</router-link>
                                        </li>
                                        <li class="c-mb-11">
                                            <router-link to="/solutions/" class="d-block">Solutions
                                            </router-link>

                                        </li>
                                        <li class="c-mb-11">
                                            <router-link to="/contact/" class="d-block">Contact</router-link>
                                        </li>
                                        <li class="c-mb-11">
                                            <router-link to="/career/" class="d-block">Career</router-link>
                                        </li>
                                        <li class="c-mb-11">
                                            <router-link to="/partners/" class="d-block">Partners</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg mb-3 d-sm-none d-md-none mb-lg-0">
                                <div class="fl-y fl-a-s w-100">
                                    <h2 class="mb-4 font-lato-bold fs-lg-1 text-danger">HELP</h2>
                                    <ul class="list-unstyled mb-3 ul-footer fs-lg--1">
                                        <!--                                        <li class="c-mb-11">-->
                                        <!--                                            <router-link to='/' class="d-block">Terms & Conditions</router-link>-->
                                        <!--                                        </li>-->
                                        <li class="c-mb-11">
                                            <router-link to='/privacy-policy/' class="d-block">Privacy Policy
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <template v-if="currentUser.address">
                                <template v-for="(address, index) in currentUser.address">
                                    <div :key="'a-' + index" v-if="index < 2"
                                         class="col-12 col-md-6 col-lg-3 mb-3 d-sm-none d-md-none mb-lg-0">
                                        <div class="fl-y fl-a-s w-100">
                                            <h2 class="mb-4 font-lato-bold fs-lg-1 text-danger">{{ address.place }}</h2>
                                            <ul class="list-unstyled mb-3 ul-footer fs-lg--1">
                                                <li class="c-mb-11">
                                                    <div class="fl-x cursor-pointer"
                                                         @click="openMap(address.latitude, address.longitude)">
                                            <span class="mr-3">
                                                <img src="../../assets/web/Icons/footer/office-icon.svg" alt="">
                                            </span>
                                                        <p class="text-3 text-justify">
                                                            {{ address.address }}
                                                        </p>
                                                    </div>

                                                </li>
                                                <li class="c-mb-11">
                                                    <div class="fl-x">
                                            <span class="mr-3">
                                                <img src="../../assets/web/Icons/footer/phone-icon.svg" alt="">
                                            </span>
                                                        <div class="text-3">
                                                            <p class="m-0">+91 {{ address.phone_number }}</p>
                                                            <p>{{ address.alternative_phone_number }}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="cursor-pointer c-mb-11">
                                                    <a :href="'mailto:' + address.email" class="fl-x">
                                                        <span class="mr-3">
                                                            <img src="../../assets/web/Icons/footer/mail-icon.svg"
                                                                 alt="">
                                                        </span>
                                                        <div class="text-3 cursor-pointer">
                                                            <p class="m-0">
                                                                {{ address.email }}</p>
                                                        </div>
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <div class="col-12">
                                <div class="d-sm-none mt-4"
                                     style="width:100%; height: 1px; background-color: var(--text-3);"></div>
                            </div>
                        </div>
                        <div class="row px-4 px-lg-0 pb-4 pb-lg-0 bb-1-sm">
                            <div class="col-12 col-lg mb-lg-0 d-lg-none">
                                <base-accordion color="" heading="COMPANY" :border-top="false" :border-bottom="true">
                                    <template #default>
                                        <div class="fl-x fl-j-s ml-5">
                                            <ul class="list-unstyled mb-3 ul-footer">
                                                <li class="mb-1">
                                                    <router-link to="/about/" class="d-block">About</router-link>
                                                </li>
                                                <!--                                                <li class="c-mb-11">-->
                                                <!--                                                    <router-link to="/services/" class="d-block ">Services-->
                                                <!--                                                    </router-link>-->
                                                <!--                                                </li>-->
                                                <li class="c-mb-11">
                                                    <router-link to="/blog/" class="d-block ">Blog
                                                    </router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/training-centers/" class="d-block">Solutions
                                                    </router-link>

                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/contact/" class="d-block">Contact
                                                    </router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/career/" class="d-block">Career</router-link>
                                                </li>
                                                <li class="c-mb-11">
                                                    <router-link to="/partners/" class="d-block">Partners
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>
                                </base-accordion>
                            </div>

                            <div class="col-12 col-lg mb-3 mb-lg-0 d-lg-none">
                                <base-accordion color="" heading="HELP" :border-bottom="true">
                                    <template #default>
                                        <div class="fl-y fl-a-s ml-5">
                                            <ul class="list-unstyled mb-3 ul-footer">
<!--                                                <li class="c-mb-11">-->
<!--                                                    <router-link to='/' class="d-block">Terms & Conditions</router-link>-->
<!--                                                </li>-->
                                                <li class="c-mb-11">
                                                    <router-link to='/privacy-policy/' class="d-block">Privacy Policy
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>
                                </base-accordion>

                            </div>
                            <div class="col-12 col-lg mb-3 mb-lg-0 d-lg-none" v-if="currentUser.address">
                                <template v-for="(address, index) in currentUser.address">
                                    <base-accordion :key="'b-' + index" v-if="index < 2" color=""
                                                    :heading="address.place" :border-bottom="true">
                                        <template #default>
                                            <div class="fl-y fl-a-s ml-5">
                                                <ul class="list-unstyled mb-3 ul-footer">
                                                    <li class="c-mb-11">
                                                        <div class="fl-x cursor-pointer"
                                                             @click="openMap(address.latitude, address.longitude)">
                                            <span class="mr-3">
                                                <img src="../../assets/web/Icons/footer/office-icon.svg" alt="">
                                            </span>
                                                            <p class="text-3 text-justify">
                                                                {{ address.address }}</p>
                                                        </div>

                                                    </li>
                                                    <li class="c-mb-11">
                                                        <div class="fl-x">
                                                            <span class="mr-3">
                                                                <img src="../../assets/web/Icons/footer/phone-icon.svg"
                                                                     alt="">
                                                            </span>
                                                            <div class="text-3">
                                                                <p class="m-0">+91 {{ address.phone_number }}</p>
                                                                <p>{{ address.alternative_phone_number }}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="cursor-pointer c-mb-11">
                                                        <a :href="'mailto:' + address.email" class="fl-x">
                                                            <span class="mr-3">
                                                                <img src="../../assets/web/Icons/footer/mail-icon.svg"
                                                                     alt="">
                                                            </span>
                                                            <div class="text-3">
                                                                <p class="m-0">{{ address.email }}</p>
                                                            </div>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </template>
                                    </base-accordion>
                                </template>
                            </div>
                        </div>
                        <div class="row px-4 px-lg-0">
                            <div class="col-12 mt-4">
                                <div class="fl-te-c-res">
                                    <p class="mb-lg-0 fs--2">&copy; CyberFort {{ new Date().getFullYear() }} All Rights
                                        Reserved
                                    </p>
                                    <ul class="fl-x text-decoration-none">
                                        <span @click="redirectToLink('https://twitter.com/CyberfortM')"
                                              class="cursor-pointer px-3 px-lg-2">
                                            <img class="img-w-sm"
                                                 src="../../assets/web/Icons/footer/social/twitter-icon.svg" alt="">
                                        </span>
                                        <span
                                            @click="redirectToLink('https://www.facebook.com/Cyberfort-DigiSec-Solution-104112385577304/?ref=pages_you_manage')"
                                            class="cursor-pointer px-3 px-lg-2">
                                            <img class="img-w-sm"
                                                 src="../../assets/web/Icons/footer/social/facebook.svg" alt="">
                                        </span>
                                        <span
                                            @click="redirectToLink('https://www.linkedin.com/company/cyberfort-digisec-solution-private-ltd/?viewAsMember=true')"
                                            class="cursor-pointer px-3 px-lg-2">
                                            <img class="img-w-sm"
                                                 src="../../assets/web/Icons/footer/social/linkedin.svg" alt="">
                                        </span>
                                        <span @click="redirectToLink('https://wa.me/9345242900')"
                                              class="cursor-pointer px-3 px-lg-2">
                                            <img class="img-w-sm"
                                                 src="../../assets/web/Icons/footer/social/whatsapp.svg" alt="">
                                        </span>
                                        <span
                                            @click="redirectToLink('https://www.instagram.com/accounts/login/?next=/cyberfort_digisec_solution/')"
                                            class="cursor-pointer px-3 px-lg-2">
                                            <img class="img-w-sm"
                                                 src="../../assets/web/Icons/footer/social/instagram.svg" alt="">
                                        </span>
                                    </ul>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="layer-sm d-lg-none"></div>
        </footer>
    </div>
</template>

<script>
import BaseAccordion from '../../components/ui/BaseAccordion';
import FooterResponsiveTopLayer from '../../components/footer/FooterResponsiveTopLayer';
import { mapGetters } from 'vuex';

export default {
    name: 'MainFooter',
    computed: {
        ...mapGetters(['currentUser'])
    },

    components: { FooterResponsiveTopLayer, BaseAccordion },

    props: {

        currentRoute: {
            type: String,
            default: ''
        }
    },
    methods: {
        redirectToLink (link) {
            window.open(link);
        },
        openMap (lat, lon) {
            if (!lat || !lon) {
                lat = '13.033995689785085';
                lon = '80.23570321239274';
            }
            window.open('https://www.google.com/maps/place/' + lat + ',' + lon);
        }
    }
};
</script>

<style scoped lang="scss">
.footer-contain {
    background: var(--bg-2) !important;
}

.layer-sm {
    position: absolute;
    height: 5rem;
    width: 100vw;
    top: -3rem;
    background: url("../../assets/web/bg/footer/footer-bg.svg");
}

.underline-item {
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -7px;
    left: 0;
    background-color: rgba(228, 233, 242, 0.6);
}

.social-links {
    display: flex;
    //width: 80%;
    justify-content: flex-start;
    list-style: none;

    li {
        :not(:last-child) {
            transition: all ease-in-out 300ms;
            border: 2px solid #ddd;
            opacity: 0.6;
            border-radius: 0.5rem;
            height: 45px;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 1.6rem;
                color: white;
            }

            &:hover {
                //transform: scale(1.03);

                i {
                    color: #9e9e9e;
                }
            }
        }
    }
}

.top-footer-cont {
    //background-color: #262626;
    //color: #888888;

    .text-head {
        //font-weight: bold !important;
        //font-size: 1.2rem;
        font-family: 'inter-semi-bold', sans-serif;
        margin-bottom: var(--spacer-4);
        color: #fafafa;
    }

    .text-details {
        font-weight: bold !important;
        font-family: 'Montserrat', sans-serif;
        color: #ffffff;
    }

    .list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            position: relative;
            padding: 10px 0;
            border-bottom: 1px solid #333333;
            transition: 0.3s;

            &:hover {
                color: #cccccc;

                &:after {
                    color: #cccccc;
                }
            }

            &:after {
                font-family: "FontAwesome";
                content: "\f105";
                position: absolute;
                top: 10px;
                right: 0;
                font-size: 14px;
                color: #999999;
            }
        }
    }

}

ul {
    a {
        text-decoration: none;
    }
}

a:hover {
    color: #cccccc;
}

.bottom-footer-cont {
    background-color: #1b1b1b;
    //color: #777777;
    color: var(--color-white);

    .bottom-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .social-icons {
            li {
                display: inline-block;
                font-size: 1.4rem;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }

    @media (min-width: 992px) {
        .bottom-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

.w-60 {
    width: 60% !important;
}

//.text-white {
//    &:hover {
//        opacity: 0.6 !important;
//    }
//}

.ul-footer {
    li {
        margin-bottom: var(--spacer-3) !important;

        a {
            font-size: 1rem;
            color: var(--text-3);
        }

    }
}

.c-address {
    font-size: 0.9rem;
    font-style: normal !important;
    //line-height: 2rem;
}

.line-top {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(228, 233, 242, 0.2);
}

.border-bottom {
    border-bottom: 1px solid rgba(228, 233, 242, 0.2);
}

.border-top {
    border-top: 1px solid rgba(228, 233, 242, 0.2);
}

.footer-logo-container {
    img {
        min-height: 3.2rem;
    }
}

.social-icon {
    i {
        font-size: 1.7rem;
        color: rgba(255, 255, 255, 0.7);
    }
}

.footer-bg {
    //background: url("../assets/web/landing/footer-bgi.jpg");
    background-position: bottom;
    background-size: cover;
    background: #030355;
    margin-top: -10rem;
    @media (min-width: 992px) {
        margin-top: 0;
        padding-top: 14rem !important;
        background: url("../../assets/web/bg/footer-bg.svg");
        background-image: url("../../assets/web/bg/footer/footer-bg.svg");
        background-repeat: no-repeat;
        background-size: cover;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        margin-top: -8rem;
    }
    //clip-path: polygon(0% 99%, 0% 88%, 0% 76%, 0% 66%, 1% 57%, 2% 52%, 3% 46%, 100% 0%, 100% 99%);
    //clip-path: polygon(0% 100%, 0% 97%, -2% 79%, 0% 60%, 1% 59%, 2% 57%, 8% 51%, 100% 0%, 100% 99%);
    //clip-path: polygon(0% 100%, 0% 100%, -2% 66%, 1% 61%, 2% 59%, 3% 57%, 8% 51%, 100% 0%, 100% 99%);

}

.footer-logo {
    height: 3.2rem;
    @media (min-width: 992px) {
        height: 2.5rem;
    }
}

.footer-credit-links {
    a {
        position: relative;
        padding: 0 var(--spacer-2);

        &:not(:last-child)::after {
            content: '';
            position: absolute;
            right: 0;
            height: 60%;
            transform: translateY(40%);
            width: 1.2px;
            background-color: rgba(255, 255, 255, 0.7) !important;
        }

    }
}

.img-w-sm {
    width: 1.5rem;
    height: 1.5rem;
    @media (min-width: 992px) {
        width: auto;
        height: auto;
    }
}

//.footer-fonts {
//    h2 {
//        color: rgba(255, 255, 255, 0.9) !important;
//    }
//}
</style>
