<template>
    <div class="c-bg-2" id="top">

        <Loader v-if="loading"/>
        <notifications/>

        <div class="nav-container bs-5">
            <lego-nav-bar child-menu-additional-classes="mt-lg-3" ul-classes="" drop-down-animation="fade" drop-down-animation-mobile="fade" :colored="false" :items="menu"
                          shadow="0" class="container" @link-event="linkEvent" :has-separator="false">
                <template #logo>
                    <router-link to="/">
                        <img src="../assets/web/Logo/cyberfort.png" alt="">
                    </router-link>
                </template>
            </lego-nav-bar>
        </div>

        <modal dialog ref="registeration" width="35r" :no-close-on-backdrop="true" title="Training & Certification"
               class="custom-modal"
               :cancel-button="''" hideFooter>
            <validation-observer slim v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="p-4 bh-info" v-if="collecting">
                        <loading-animation/>
                        Loading Data..
                    </div>
                    <div class="p-4 text-center" v-else>
                        <validated-vue-select v-model="model.choose_course" placeholder="Select Course"
                                              class="c-input-modal" @input="loadScheduleOptions"
                                              :rules="{required: true}" name="Choose course"
                                              :options="courseOptions"></validated-vue-select>
                        <div class="row m-0 mb-4 b-2 round-1 fs--1" v-if="model.choose_course">
                            <div class="col-md-4 p-3 d-flex fl-y-c c-bb-2 br-lg-2">
                                <p class="mb-2">Training Type</p>
                                <p class="proxim-bold mb-0">{{ training.type_of_training }}</p>
                            </div>
                            <div class="col-6 col-md-4 p-3 d-flex fl-y-c br-2">
                                <p class="mb-2">Duration</p>
                                <p class="proxim-bold mb-0">{{ training.duration }} : Pl Contact
                                    info(at)cyberfort.in</p>
                            </div>
                            <div class="col-6 col-md-4 p-3 d-flex fl-y-c">
                                <p>
                                    <span class="proxim-bold">Fees : </span> ₹ {{ training.course_fee }}
                                    plus {{ training.tax_percentage }}% GST (₹
                                    {{ training.fees_number - training.course_fee }})
                                </p>
                                <p class="">
                                    <span class="proxim-bold">Total Fee : </span>
                                    <span>{{ training.fees_number }}</span>
                                </p>
                            </div>
                        </div>
                        <validated-vue-select v-model="model.event_training_schedule" :rules="{required: true}"
                                              name="Event training schedule"
                                              placeholder="Select Date" class="c-input-modal"
                                              :options="scheduleOptions"></validated-vue-select>
                        <validated-vue-select v-model="model.country" placeholder="Select Country"
                                              class="c-input-modal" name="Country"
                                              :rules="{required: true}"
                                              :options="countriesOptions"></validated-vue-select>
                        <validated-input v-model="model.city" placeholder="Select City" name="City"
                                         class="c-input-modal" :rules="{required: true}"></validated-input>
                        <validated-input v-model="model.name" placeholder="Name" name="Name"
                                         class="c-input-modal" :rules="{required: true}"></validated-input>
                        <validated-input name="Phone number" v-model="model.phone_number" type="number"
                                         :rules="{required: true}" class="c-input-modal"
                                         placeholder="Contact Number"></validated-input>
                        <validated-input v-model="model.email" placeholder="Your Email Id" name="Email" type="email"
                                         class="c-input-modal" :rules="{required: true}"></validated-input>
                        <div class="row mb-3">
                            <div class="col-12 fl-te-c">
                                <img class="img-fluid" v-if="model.captcha_URL" :src="model.captcha_URL + '200/'"
                                     alt="Captcha"/>

                                <div @click="loadCaptcha" class="col-2">
                                    <loading-animation v-if="captchaLoading" class="text-primary"/>
                                    <i v-else class="fa fa-refresh"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <validated-input v-model="model.captcha" name="captcha" :rules="{required: true}"
                                                 placeholder="Captcha" class="c-input-modal mb-0"/>
                                <span class="text-3 fs--1 text-danger" v-if="captchaError">{{ captchaError }}</span>
                            </div>
                        </div>
                        <btn color="third" class="Proxima-Semibold px-5" border-radius="0" design="basic-a"
                             text="Register"></btn>
                    </div>
                </form>
            </validation-observer>
        </modal>

        <modal dialog ref="paymentContinue" :no-close-on-backdrop="true" width="30r" title=" "
               class="custom-modal fl-x-c no-close"
               :cancel-button="''" hideFooter>
            <div class="p-4 text-center">
                <p>Are you sure you want to continue payment section ?</p>
                <div class="row fl-j-c mt-4">
                    <div class="col-4">
                        <btn text="Cancel" @click="$refs.paymentContinue.close()" border-radius="0-5" color="secondary"
                             design="basic-b" block></btn>
                    </div>
                    <div class="col-4">
                        <btn color="third" @click="registerStatus" class="Proxima-Semibold px-3 px-xl-5 ml-3"
                             border-radius="0" design="basic-b" text="Continue"></btn>
                    </div>
                </div>
            </div>

        </modal>

        <modal dialog ref="paymentConfirm" width="30r" :no-close-on-backdrop="true" title="Training Payment"
               class="custom-modal" :cancel-button="''" hideFooter>
            <div class="p-4 text-center">
                <validated-input border-radius="0" v-model="payModel.name" label="Name"></validated-input>
                <validated-input border-radius="0" v-model="payModel.email" label="Email"></validated-input>
                <validated-input border-radius="0" v-model="payModel.phone" label="Phone Number"></validated-input>
                <validated-input border-radius="0" v-model="payModel.training_name"
                                 label="Training Name"></validated-input>
                <validated-input border-radius="0" v-model="payModel.date" label="Date"></validated-input>
                <validated-input border-radius="0" v-model="payModel.amount" label="Fee"></validated-input>
                <button @click="payToRedirect"
                        class="lego-btn btn-bg-c btn-basic btn-color-primary round-1 bs-1 bs-1-h p-1 mt-2 mb-1">
                    <div class="btn-bg px-5 Proxima-Semibold">Pay Now</div>
                </button>
            </div>
        </modal>

        <modal dialog ref="paymentRedirect" :no-close-on-backdrop="true" width="30r" title="Payment"
               class="custom-modal" :cancel-button="''"
               hideFooter>
            <RedirectPage :model="payModel"/>
        </modal>

        <div class="page-p-t bg-2">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>

        <main-footer></main-footer>

        <Cookies/>

        <modal class="c-modal modal-body modal-header  header-primary" :no-close-on-backdrop="true"
               title="Training and Certification"
               ref="trainingRegisterModal" width="40p" header-color="primary">
            <training-certification-form></training-certification-form>
        </modal>
    </div>

</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';
import MainFooter from '@components/footer/MainFooter';
import axios from 'secure-axios';
import urls from '../data/urls';
import Loader from '../components/Loader';
import RedirectPage from '../views/components/services/training/RedirectPage';
import Cookies from '../views/components/single-components/cookies';
import TrainingCertificationForm from '@components/home-components/TrainingCertificationForm';

export default {
    name: 'MainLayout',
    components: {
        TrainingCertificationForm,
        MainFooter,
        LegoNavBar,
        Cookies,
        Loader,
        RedirectPage
    },
    data () {
        return {
            menu: getMenu(),
            value: '',
            model: {
                event_training_schedule: '',
                country: '',
                city: '',
                name: '',
                phone_number: '',
                email: '',
                captcha_URL: '',
                captcha_key: '',
                captcha: ''
            },
            captchaError: '',
            captchaLoading: false,
            courseOptions: [],
            scheduleOptions: [],
            countriesOptions: [],
            collecting: false,
            training: null,
            loading: false,
            regID: '',
            payModel: {
                amount: '',
                firstname: '',
                name: '',
                email: '',
                phone: '',
                training_name: '',
                action: '',
                address1: '',
                address2: '',
                city: '',
                country: '',
                furl: '',
                hash_string: '',
                hash: '',
                key: '',
                lastname: '',
                merchant_key: '',
                productinfo: '',
                service_provider: '',
                state: '',
                surl: '',
                txnid: '',
                udf1: '',
                udf2: '',
                udf3: '',
                udf4: '',
                udf5: '',
                zipcode: ''
            }
        };
    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    methods: {
        async loadCaptcha () {
            this.captchaLoading = true;
            const response = await axios.get(urls.getCaptcha);
            const json = response.data;
            if (json.error === false) {
                this.model.captcha_key = json.key;
                this.model.captcha_URL = json.image_url;
                this.model.captcha = '';
                setTimeout(() => {
                    this.captchaLoading = false;
                }, 1000);
            } else {
                this.$notify('Could not load captcha, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            setTimeout(() => {
                this.captchaLoading = false;
            }, 1000);
        },
        linkEvent (name) {
            console.log('linkEvent');
            if (name === 'register') {
                alert('register');
                this.$refs.trainingRegisterModal.show();
            }
        },
        sideNav () {
            const element1 = document.getElementById('sidebar');
            const element2 = document.getElementById('overlay');

            if (this.customClass === 'active') {
                element1.classList.remove('active');
                element2.classList.remove('active');
            } else {
                element1.classList.add('active');
                element2.classList.add('active');
            }
        },
        clicked () {
            const element1 = document.getElementById('sidebar');
            const element2 = document.getElementById('overlay');

            this.customClass = '';

            element1.classList.remove('active');
            element2.classList.remove('active');
        },

        loadOptions () {
            const that = this;
            that.loading = true;
            axios.get(urls.regInitials).then(function (response) {
                const json = response.data;
                that.courseOptions = json.choose_course.data;
                that.countriesOptions = json.countries.data;
                that.model.captcha_URL = json.image_url;
                that.model.captcha_key = json.key;
                that.loading = false;
            }).then(function (error) {
                console.log('error : ', error);
            });
        },

        loadScheduleOptions () {
            const that = this;
            that.setShowData();
            axios.form(urls.scheduleOptions, { course: that.model.choose_course }).then(function (response) {
                const json = response.data;
                that.scheduleOptions = json.data;
            }).catch(function (error) {
                console.log('error : ', error);
            });
        },

        setShowData () {
            const item = this.courseOptions.filter(obj => obj.value === this.model.choose_course);
            this.training = item[0];
        },

        onSubmit () {
            const that = this;
            that.loading = true;
            const formValidator = that.$refs.formValidator;
            axios.form(urls.registration, that.model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.regID = json.reg_id;
                    that.loading = false;
                    that.$refs.registeration.close();
                    that.$refs.paymentContinue.show();
                } else {
                    if (json.captcha === false) {
                        that.captchaError = 'Invalid Captcha';
                        that.model.captcha_URL = json.image_url;
                        that.model.captcha_key = json.key;
                        that.loading = false;
                    } else {
                        that.loading = false;
                        formValidator.setErrors(json.errors);
                        that.message = json.errors.__all__ || '';
                        that.formError = json.errors;
                    }
                }
            }).catch(function (err) {
                console.log('error', err);
            });
        },

        registerStatus () {
            const that = this;
            that.loading = true;
            axios.form(urls.paymentInitials, { reg_id: that.regID }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.setModel(json.payu);
                    that.loading = false;
                    that.$refs.paymentContinue.close();
                    that.$refs.paymentConfirm.show();
                } else {
                    that.loading = false;
                }
            }).catch(function (err) {
                console.log('error : ', err);
            });
        },

        setModel (data) {
            this.payModel.action = data.action;
            this.payModel.name = data.name;
            this.payModel.training_name = data.training_name;
            this.payModel.date = data.date;
            this.payModel.address1 = data.address1;
            this.payModel.address2 = data.address2;
            this.payModel.amount = data.amount;
            this.payModel.city = data.city;
            this.payModel.country = data.country;
            this.payModel.email = data.email;
            this.payModel.firstname = data.firstname;
            this.payModel.furl = data.furl;
            this.payModel.hash_string = data.hash_string;
            this.payModel.hash = data.hashh;
            this.payModel.key = data.key;
            this.payModel.lastname = data.lastname;
            this.payModel.merchant_key = data.merchant_key;
            this.payModel.phone = data.phone;
            this.payModel.productinfo = data.productinfo;
            this.payModel.service_provider = data.service_provider;
            this.payModel.state = data.state;
            this.payModel.surl = data.surl;
            this.payModel.txnid = data.txnid;
            this.payModel.udf1 = data.udf1;
            this.payModel.udf2 = data.udf2;
            this.payModel.udf3 = data.udf3;
            this.payModel.udf4 = data.udf4;
            this.payModel.udf5 = data.udf5;
            this.payModel.zipcode = data.zipcode;
        },

        payToRedirect () {
            this.$refs.paymentConfirm.close();
            this.$refs.paymentRedirect.show();
        }
    }
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.page-p-t {
    padding-top: 5.5rem !important;
    /*padding-top:7.5rem !important;*/
}

</style>
<!--Todo-->
<!--Style from old project-->
<!--Remove if not needed-->
<style lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.sidebar-items {
    .dropdown {
        width: 100%;

        button {
            text-align: left;
            background: white;
            color: black;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-shadow: none;

            &:hover {
                background: white;
                color: black;
            }
        }

        .dropdown-menu {
            width: 100%;
            font-size: 1rem;
            box-shadow: none;
            position: unset;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
